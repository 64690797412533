<template>
  <div>
    <AutoPopup />
    <b-row>
      <b-col>
        <div class="a-profile-subpage-title" :class="`-${userInfo.type}`">
          <div>
            <h1>
              <Loadable
                :items="userInfo.type"
                :msgLoaded="userInfo.type === 'student' ? 'Študent' : 'Učiteľ'"
              />
            </h1>
            <div class="z-fs-small">{{ $t("Typ registrácie") }}</div>
          </div>
          <div :class="{ 'text-right': userInfo.type === 'teacher' }">
            <time class="fw-bold">{{
              moment(userInfo.updatedAt).format("DD.MM.YYYY")
            }}</time>
            <div class="z-fs-small">{{ $t("Posledná aktualizácia") }}</div>
          </div>
          <div v-if="userInfo.type === 'student'">
            <div class="z-icon-wrapper -large">
              <svg-school-icon class="w-color-primary" />
            </div>
            <div class="form-group flex-between mb-0">
              <label
                for="attendingSchool"
                class="mr-3"
                :class="{ 'fw-bold': userInfo.attendingSchool }"
              >
                {{ $t("Študuješ momentálne na škole?") }}
              </label>
              <b-form-checkbox
                v-model="userInfo.attendingSchool"
                id="attendingSchool"
                class="a-checkbox-circle"
              />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs>
          <b-tab :title="$t('Osobné údaje')" active>
            <b-form class="mt-sm-5 mt-3">
              <UserInfo :user="userInfo" />
              <RegisterForm :profile="userInfo" :isOnProfilePage="true" />
            </b-form>
            <div class="text-right">
              <b-button
                type="button"
                variant="primary"
                class="-wider"
                @click.prevent="save"
                >{{ $t("Uložiť zmeny") }}</b-button
              >
            </div>
          </b-tab>
          <b-tab :title="$t('Zmeniť heslo')" class="changepwdTab">
            <ProfileChangePassword class="mt-sm-5 mt-3" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { merge } from "lodash";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import AutoPopup from "/components/AutoPopup.vue";

import * as Sentry from "@sentry/vue";

export default {
  layout: "profile",

  components: {
    AutoPopup,
    UserInfo: () => import("/components/UserInfo"),
    ProfileChangePassword: () =>
      import("/components/Profile/ProfileChangePassword"),
    RegisterForm: () => import("/components/RegisterForm"),
    "svg-school-icon": () => import("/assets/icons/school.svg?inline"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  data() {
    return {
      activeTabIndex: 0,
      didntFindMySchool: false,
      userInfo: {
        _lang: this.$root.$i18n.locale,
        type: null,
        attendingSchool: false,
        school: null,
        subjects: [],
        subjectsOther: "",
        phone: "",
        comment: "",
        state: null,
        marketingAccepted: true,
        updatedAt: null,
        firstName: "",
        lastName: "",
        email: "",
        gdprAccepted: true,
        schoolConnect: {
          id: null,
          cityConnect: {
            id: null,
          },
        },
        schoolCreate: {
          name: "",
          address: "",
          cityCreate: {
            name: "",
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.userInfo && this.userInfo.type === "teacher";
    },
  },

  watch: {
    "$root.$i18n.locale": {
      handler: function (locale) {
        this.userInfo._lang = locale;
      },
    },
  },

  async mounted() {
    try {
      const storeUserInfo = await this.$store.dispatch("auth/user");
      this.userInfo = merge(this.userInfo, storeUserInfo);
    } catch (error) {
      Sentry.captureException(error);
      this.userInfo = { error: "Nepodarilo sa načítať Váš profil..." };
    }
  },

  methods: {
    async save() {
      try {
        await this.$store.dispatch("auth/updateUser", this.userInfo);
        this.$toast.success(this.$t("Zmeny boli uložené"));
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    moment,
  },
};
</script>

<style lang="scss" scoped>
.changepwdTab {
  ::v-deep button {
    float: right;
  }
}

.a-profile-subpage-title {
  &.-student {
    > div {
      display: flex;

      &:first-of-type {
        padding-right: 4rem;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 30%;
      }

      &:last-of-type {
        padding-left: 2.5rem;
        flex: 1 0 0;
      }

      &:not(:last-of-type):not(:first-of-type) {
        justify-content: center;
        padding: 0 3rem;
        flex: 0 0 30%;
        height: 158px;
        flex-direction: column;
        border-right: 1px solid var(--a-color-blue-border);
        border-left: 1px solid var(--a-color-blue-border);
      }

      @media only screen and (max-width: 991px) {
        &:first-of-type,
        &:not(:last-of-type):not(:first-of-type) {
          padding: 0;
          flex: 0 0 100%;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &:last-of-type {
          padding: 0;
          flex: 0 0 100%;

          .form-group {
            flex: 1 0 0;
          }
        }

        &:not(:last-of-type):not(:first-of-type) {
          border-left: 0;
          border-right: 0;
          height: auto;
          padding: 1rem 0;
          border-top: 1px solid var(--a-color-blue-border);
          border-bottom: 1px solid var(--a-color-blue-border);
          margin-bottom: 2rem;
        }
      }

      @media only screen and (max-width: 575px) {
        &:first-of-type {
          flex: 0 0 100%;
        }

        &:not(:last-of-type):not(:first-of-type) {
          flex: 0 0 100%;
        }
      }
    }
  }
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

time {
  font-size: 30px;
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  time {
    font-size: 1.5rem;
  }

  .tabs {
    button,
    ::v-deep button {
      width: 100%;
      float: none;
    }
  }
}

@media only screen and (min-width: 576px) {
  .z-icon-wrapper {
    &.-large {
      --icon-size: 42px;
    }
  }

  .a-profile-subpage-title {
    &.-student {
      padding: 4.5rem 3rem 4.5rem 3.5rem;
    }
  }
}
</style>
